import Vue from "vue";

function addZero(val) {
  if (val < 10) {
    return "0" + val;
  } else {
    return val;
  }
}

//格式化时间
Vue.filter("formatTime", function (value, type="YMD") {
  if (!value) return null;
  var dataTime = "";
  var data = new Date(value);
  var year = data.getFullYear();
  var month = addZero(data.getMonth() + 1);
  var day = addZero(data.getDate());
  var hour = addZero(data.getHours());
  var minute = addZero(data.getMinutes());
  var second = addZero(data.getSeconds());
  if (type == "YMD") {
    dataTime = year + "-" + month + "-" + day;
  } else if (type == "YMDHMS") {
    dataTime =
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
  } else if (type == "HMS") {
    dataTime = hour + ":" + minute + ":" + second;
  } else if (type == "YM") {
    dataTime = year + "-" + month;
  }
  return dataTime; //将格式化后的字符串输出到前端显示
});

//用户状态status
Vue.filter("status", function (status) {
  if (status == -1) return "审核不通过";
  else if (status == 0) return "审核中";
  else if (status == 1) return "审核通过";
});

//会员类型
Vue.filter("vip", function (opt) {
  if (opt == 0) return "非会员";
  else if (opt == 1) return "普通会员";
  else if (opt == 2) return "超级会员";
});

//数据来源
Vue.filter("sourceType", function (opt) {
  if (opt == 0) return "平台自建";
  else if (opt == 1) return "后台导入";
});

//性别
Vue.filter("sex", function (opt) {
  if (opt == 0) return "男";
  else if (opt == 1) return "女";
});

//等级
Vue.filter("level", function (opt) {
  if (opt == 0) return "超级管理员";
  else if (opt == 1) return "省份";
  else if (opt == 2) return "城市";
  else if (opt == 3) return "区域";
  else if (opt == 4) return "学校";
  else if (opt == 5) return "专家";
  else if (opt == 6) return "班级";
  else if (opt == 7) return "年级";
});
