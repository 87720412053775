import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/login.vue";
import Home from "../views/index.vue";

Vue.use(VueRouter);

//解决重复点击路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: "/",
    name: "main",
    redirect: "/login",
  },
  {
    path: "/dataBoard",
    name: "dataBoard",
    component: ()=>import("@/views/dataBoard/index"),
    meta: {
      name: "大屏",
    },
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      name: "登录",
    },
  },
  {
    path: "/home",
    name: "home",
    redirect: "/home/index",
    component: Home,
    children: [
      {
        path: "/user/index",
        name: "user",
        component: () => import("@/views/user/index"),
        meta: {
          name: "用户列表",
          isMenu: true,
        },
      },
      {
        path: "/wechat/index",
        name: "wechat",
        component: () => import("@/views/wechat/index"),
        meta: {
          name: "微信用户",
          isMenu: true,
        },
      },
      {
        path: "/expert/index",
        name: "expert",
        component: () => import("@/views/expert/index"),
        meta: {
          name: "专家列表",
          isMenu: true,
        },
      },
      {
        path: "/admin/index",
        name: "admin",
        component: () => import("@/views/admin/index"),
        meta: {
          name: "管理员列表",
          isMenu: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
