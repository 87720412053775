<template>
  <div class="login">
    <div class="content">
      <img src="@/assets/images/login-home.png" class="login-home" alt="" />
      <div class="loginBox">
        <h2>欢迎使用众高管理平台</h2>
        <div class="userBox">
          <el-form ref="form" :model="loginForm" label-width="0">
            <el-form-item label="">
              <el-input
                placeholder="请输入手机号或邮箱"
                v-model="loginForm.username"
                ref="username"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input
                type="password"
                placeholder="请输入登录密码"
                v-model="loginForm.password"
                ref="password"
              >
              </el-input>
            </el-form-item>
          </el-form>
          <div class="pwdBox">
            <el-checkbox
              true-label="Y"
              false-label="N"
              ><span style="font-size: 12px">记住密码</span></el-checkbox
            >
          </div>
          <el-button type="primary" class="login-btn" @click="handleLogin()"
            >登录</el-button
          >
          <p class="ysTips">
            登录即代表您同意<a href="javascript:;"
              >《用户使用协议》和《隐私政策》</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
    };
  },
  mounted() {
    if (this.loginForm.username === "") {
      this.$refs.username.focus();
    } else if (this.loginForm.password === "") {
      this.$refs.password.focus();
    }
  },
  methods: {
    handleLogin() {
      this.getAjax(
        "api/admin/login",
        {
          userName: this.loginForm.username,
          passWord: this.loginForm.password,
        },
        "post"
      ).then((res) => {
        this.setItem("info", res.data);
        this.$router.push({
          name: "user",
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 14px !important;
}

.login {
  width: 100%;
  height: 100%;
  background: url("../assets/images/login-bg.jpg") top center;
  background-size: cover;
  box-sizing: border-box;
  min-width: 1400px;
  overflow-x: auto;
  box-shadow: -1px -1px 10px #043795;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    font-size: 16px;
  }

  .yzmBox {
    display: flex;
    align-items: center;

    .yzm {
      margin-left: 20px;
      cursor: pointer;
    }

    .send-btn {
      margin-left: 10px;
      width: 84px;
      height: 32px;
      border: 1px solid #ccc;
      cursor: pointer;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }

    .notSend {
      background: #fff;
      color: #999;
      cursor: default;
    }
  }

  .content {
    padding: 40px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1080px;
    height: 512px;
    box-sizing: border-box;
    border-radius: 8px;

    .login-home {
      flex: 1;
      margin-right: 40px;
    }

    .loginBox {
      width: 380px;
      height: 430px;
      background: #fff;
      border-radius: 5px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      padding: 32px 40px 40px;

      h2 {
        font-size: 22px;
        font-family: Microsoft YaHei;
        color: #333333;
        font-weight: normal;
        text-align: center;
        line-height: 1;
        margin-bottom: 60px;
      }

      .tips {
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin-top: 35px;

        span {
          padding: 0 20px;
          height: 30px;
          line-height: 30px;

          text-align: center;
          font-size: 14px;
          cursor: pointer;
        }

        .on {
          color: #4285f4;
          border-bottom: 2px solid #4285f4;
        }
      }

      .userBox {
        margin-top: 14px;

        .pwdBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 20px 0;
          height: 20px;
        }

        .login-btn {
          width: 100%;
          background-color: #4285f4;
          border-color: #4285f4;
        }

        .ysTips {
          font-size: 12px;
          color: #333;
          margin-top: 20px;

          a {
            color: #333;
            margin-left: 5px;
          }
        }

        .regeistBox {
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;

          span {
            font-size: 12px;
          }

          a {
            color: #4285f4;
            height: 14px;
            line-height: 1;
            display: inline-block;
            margin-top: 2px;
          }
        }
      }
    }
  }
}
</style>
