<template>
  <div class="myApp">
    <div class="headBox">
      <h2>众高管理平台</h2>
      <div class="userBox">
        <el-dropdown>
          <span class="el-dropdown-link">
            <i class="el-icon-user-solid"></i>
            {{ info.account }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="resetPwd()"
              icon="el-icon-edit-outline"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item
              @click.native="goout()"
              icon="el-icon-switch-button"
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="contentBox">
      <div class="leftBox">
        <el-menu
          :default-active="defaultActive"
          class="el-menu-vertical-demo"
          @select="handleSelect"
          @open="handleOpen"
          @close="handleClose"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <el-menu-item index="dataBoard">
            <i class="el-icon-menu"></i>
            <span slot="title">全局概览</span>
          </el-menu-item>
          <el-menu-item
            :index="item.name"
            v-for="item in menuList"
            :key="item.name"
          >
            <i class="el-icon-menu"></i>
            <span slot="title">{{ item.meta.name }}</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="rightBox">
        <router-view />
      </div>
    </div>
    <el-dialog
      top="3%"
      width="500px"
      title="修改密码"
      :visible.sync="showChangePwd"
      :close-on-click-modal="false"
    >
      <el-form ref="dialogForm" :model="form" label-width="120px">
        <el-form-item label="新密码">
          <el-input type="password" v-model="form.newPwd"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showChangePwd = false">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import router from "@/router/index.js";

export default {
  components: {},
  data() {
    return {
      defaultActive: "user",
      menuList: [],
      info: {},
      showChangePwd: false,
      form: {
        newPwd: "",
      },
    };
  },
  methods: {
    goout() {
      this.getAjax("api/wx/user/logout", {}, "post").then((res) => {
        this.removeItem();
        this.$router.push("/login");
      });
    },
    resetPwd() {
      this.form.newPwd = "";
      this.showChangePwd = true;
    },
    submitData() {
      this.getAjax(
        "api/admin/modifyPwd",
        { str: this.form.newPwd },
        "post"
      ).then((res) => {
        this.form.newPwd = "";
        this.$message.success("修改成功");
        this.showChangePwd = false;
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect(name) {
      if (name === "dataBoard") {
        window.open("/#/dataBoard", "_blank");
        return;
      }
      this.$router.push({
        name,
      });
    },
  },
  mounted() {
    this.menuList = router.getRoutes().filter((e) => e.meta.isMenu);
    this.defaultActive = this.$route.name;
    this.info = this.getItem("info");
    if (this.info.level !== "0") {
      this.menuList = this.menuList.filter((e) => {
        return e.name !== "expert" && e.name !== "wechat";
      });
    }
  },
};
</script>
